const config = {
  header: "Oz Hut",
  headerBackground: "#ff8ba7",
  bodyBackground: "#ffc6c7",
  title: "Bobs Hut",
  subTitle: "Yes, this is bobs hut",
  airtable: "https://airtable.com/embed/shr1I0RcCzlRO28ok?backgroundColor=red&viewControls=on",
  googleAnalytics: "UA-XXXXXXXX-X"
}

export default config
